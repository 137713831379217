<template>
  <div class="container-fluid bg-light">
    <!-- REG HORIZON -->
    <div class="row text-white bg-kpmg-dark-blue py-4 mb-4">
      <div class="col">
        <h1 class="kstyle">Contact</h1>
      </div>
    </div>
    <b-row>
      <b-col>
        <b-container>
          <div class="my-4">
            <h3>Leadership Team</h3>
            <a href="https://home.kpmg/uk/en/home/contacts/o/lucas-ocelewicz.html" target="_blank">Lucas Ocelewicz</a><br />
            <a href="https://home.kpmg/xx/en/home/contacts/d/kate-dawson.html" target="_blank">Kate Dawson</a>
            <h3 class="mt-3">AI Regulatory Radar</h3>
            Nicolas Milte<br />
            Ming Ser Gan
            <h3 class="mt-3">Controls</h3>
            Emily Lyttle<br />
            Rory Graham
            <h3 class="mt-3">Commodities</h3>
            Leen Broekhuizen
            <h3 class="mt-3">ESG</h3>
            ESG Tax - Jorik Jansen<br />
            ESG Reporting - Marisela Zermeno<br />
            ESG Metrics - Abigail Powell<br />
            ESG Climate Policy and Regulatory Dashboard - Sidharth Kamat<br />
            ESG Platform Manager - Isha Narayan
            <h3 class="mt-3">Market Abuse Risk Insights</h3>
            Dharam Shah<br />
            Louis Cottell
          </div>
        </b-container>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  created: function () {
    document.title = "KPMG Regulatory Horizon: Contact"
    this.$stat.log({ page: 'contact', action: 'open contact' })
  }
}
</script>
